// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <h2>This is for the Birthday girl on your 40th Birthday Hurray !!!!</h2>
//       <video width="400px" height= "400px" controls>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+11.56.56+AM+(1).mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+11.56.56+AM+(2).mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+11.56.56+AM+(3).mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+11.56.56+AM.mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+12.00.36+PM.mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+12.00.37+PM+(1).mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+12.00.37+PM.mp4' type="video/mp4"/>
//         <source src='https://s3.amazonaws.com/dreoconsulting.com/WhatsApp+Video+2024-01-25+at+8.39.08+AM.mp4' type="video/mp4"/>
//       </video>
//     </div>
//   );
// }

// export default App;


import React from 'react';

const VideoPlayer = () => {
  return (
    <div>
      <h1>Videos</h1>
      <div>
        <h2>Video 1</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Mumsy.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Lizzy.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Maxwell.MP4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Gloria.MP4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Bobby.MP4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Maureen2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/MaryAnne.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Daniella.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Baby.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Ore.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Seyi.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/Seyi+%26+Ore.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/comp+Ope.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Video 2</h2>
        <video controls>
          <source src="https://s3.amazonaws.com/www.dreoconsulting.com/HENRIETTA+%40+40.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Add more video tags for additional videos */}
      </div>
    </div>
  );
};

export default VideoPlayer;

